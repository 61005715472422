<template>
  <div>
  </div>
</template>



<script>
export default {
  name: "homePage",
  mounted() {
    // var that = this;
  },
  methods: {

  }
};
</script>

<style scoped>

.imac_iframe{

    width: 1671px;
    height: 918px;
    /* margin: 28px 0 0 21px; */
    /* -webkit-transform: scale(.3333); */
    /* -ms-transform: scale(.3333); */
    /* transform: scale(.3333); */
    /* -webkit-transform-origin: 0 0; */
    /* -ms-transform-origin: 0 0; */
    /* transform-origin: 0 0; */
    /* top: -99999px;
    left: 0; */
    /* position: absolute; */
    
  }

</style>